import { Client } from '../Axios'
import { ENDPOINT } from '../Axios/Api'
import ISearchService from './ISearchService'
import {clearServerMessage, renderServerMessage} from "@apps/Shared/Utils/ServerMessage";
import i18n from "i18next";
import {ISearchResult} from "@apps/Shared/Services/Search/interfaces";

class SearchService implements ISearchService {
    async GetSearchResults(
        query: string,
        features: string[],
        customerType: string
    ) {
        let res: ISearchResult = {};
        await Client('post', `${ENDPOINT.Search.SearchResults()}`, {
            query: query,
            features: features,
            portalType: customerType,
        })
            .then(response => {
                res = response.data
                res.status = response.status
                clearServerMessage()
            })
            .catch((error: Record<string, any>) => {
                console.log(error)
                renderServerMessage('error', i18n.t('error.disruptions'))
            })
        return res
    }
}

export default new SearchService()
