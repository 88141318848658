import React from 'react'
import {LinkType} from '../types'

interface IProps {
    type: LinkType
    url: string
    children?: any
    title?: string
    srText?: string
}

const Link = (props: IProps) => {
    switch (props.type) {
        case LinkType.Intern:
            return (
                <a href={props.url} className="link-wrapper">
                    {props.children}
                    <span className="sr-only">
                    {props.srText || props.title}
                    </span>
                </a>
            )
        case LinkType.Locked:
            return (
                <a href={props.url} className="link-wrapper">
                    {props.children}
                    <span className="sr-only">
                    {props.srText || props.title}
                    </span>
                </a>
            )
        case LinkType.Download:
            return (
                <a
                    href={props.url}
                    title={props.title}
                    download={true}
                    target="_self"
                >
                    {props.children}
                    <span className="sr-only">
                        {props.srText || props.title}
                    </span>
                </a>
            )
        case LinkType.Extern:
        default:
            return (
                <a
                    href={props.url}
                    title={props.title}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {props.children}
                    <span className="sr-only">
                        {props.srText || props.title}
                    </span>
                </a>
            )
    }
}

export default Link
