import React from 'react'
import Icon from '../../Icon'
import { IInlineLinkProps, LinkTheme } from '../types'
import { iconForLinkType } from '../utils'
import Link from '../Link'

const InlineLink = (props: IInlineLinkProps) => {
    const linkTheme = props.theme || LinkTheme.Default
    if (props.onClick) {
        return (
            <div
                className='link'
                id={props.id || undefined}
                onClick={props.onClick}
                role="link"
                tabIndex={0}
                onKeyDown={e => {
                    e.keyCode === 13 ? props.onClick(e) : ''
                }}
            >
                <span
                    className={`${props.loading ? 'loading' : ''} ${linkTheme}`}
                >
                    {props.text}{' '}
                    {!props.hideIcon && (
                        <Icon
                            inline={true}
                            iconName={iconForLinkType(props.type)}
                        />
                    )}
                </span>
            </div>
        )
    }

    return (
        <div className='link'>
            <Link type={props.type} title={props.text} url={props.url}>
                <span
                    id={props.id || undefined}
                    className={`${
                        props.loading ? 'loading' : ''
                    } ${linkTheme}`}
                >
                    {props.text}{' '}
                    {!props.hideIcon && (
                        <Icon
                            inline={true}
                            iconName={iconForLinkType(props.type)}
                        />
                    )}
                </span>
            </Link>
        </div>
    )
}

export default InlineLink