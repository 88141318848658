import {createRoot} from "react-dom/client";
import SearchApp from "@apps/SearchApp/SearchApp";
import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {I18nextProvider} from "react-i18next";
import i18n from '@apps/Shared/translation/i18n';

const container = document.getElementById('js-search-app');
const queryClient = new QueryClient();

if (container) {
    const root = createRoot(container);
    root.render(
        <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
                <SearchApp/>
            </QueryClientProvider>
        </I18nextProvider>
    );
}