import { SearchResultItem } from "../Shared/Services/Search/interfaces"


export enum SearchTheme {
    Default,
    Borderless,
}

export enum SearchColorMode {
    Default,
    DarkBackground,
}

export type Recommendation = {
    title: string
}

export type Result = {
    title: string
    items: [
        {
            title: string
            path: string
            score: number
        }
    ]
}

export type SearchProps = {
    debounceT: number
    onAutoComplete: Function
    onClear: Function
    recommendations: string[]
    results: SearchResultItem[]
    searchPagePath: string
    theme?: SearchTheme
    mode?: SearchColorMode
}

export type SearchSettings = {
    popularSearchTerms?: string[]
    searchPageUrl?: string
}