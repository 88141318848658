import { includes } from 'lodash'
import * as React from 'react'
import ReturpackIconSrc from '@apps/Shared/Components/Icon/favicon.ico'

export const MultiColoredIcons = ['returpack']

export const isMultiColored = (iconName: string) => {
    return !!includes(MultiColoredIcons, iconName)
}

export const getMultiColoredIcon = (iconName: string, size: number) => {
    switch (iconName) {
        case 'returpack':
            return (
                <img
                    alt=""
                    aria-hidden={true}
                    src={ReturpackIconSrc}
                    width={size}
                    height={size}
                />
            )
        default:
            return null
    }
}