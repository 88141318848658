import { EnvObject } from './types'

const hostName = window.location.hostname;
const origin = window.location.origin;

export const environments: EnvObject[] = [
    {
        env: 'development',
        host: [hostName],
        urls: {
            site: origin,
            api: `${origin}/api`,
            login: 'https://login.www.rp-pm-dev.local:5001',
        },
    },
    {
        env: 'integration',
        host: [hostName],
        urls: {
            site: origin,
            api: `${origin}/api`,
            login: 'https://login.www.rp-pm-test.pantamera.nu',
        },
    },
    {
        env: 'preproduction',
        host: [hostName],
        urls: {
            site: origin,
            api: `${origin}/api`,
            login: 'https://login.www.rp-pm-test.pantamera.nu',
        },
    },
    {
        env: 'production',
        host: [hostName],
        urls: {
            site: origin,
            api: `${origin}/api`,
            login: 'https://login.www.rp-pm-prod.pantamera.nu',
        },
    },
]
